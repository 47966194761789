import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import front from "../imgs/carousel/front1.webp";
import entrance from "../imgs/carousel/entrance.webp";
import lobby from "../imgs/carousel/lobby.webp";
import mess from "../imgs/carousel/mess.webp";
import lib from "../imgs/carousel/library.webp";
import books from "../imgs/carousel/saraswatiMaa.webp";
import styles from "../styles/CarouselHome.module.css";

export default function CarouselHome() {
  const [firstImage, setFirstImage] = useState(front);

  const handleChangeImage = () => {
    const currentPage = window.location.pathname;
    if (currentPage === "/amenities") {
      setFirstImage(mess);
    } else if (currentPage === "/contact") {
      setFirstImage(lib);
    }
  };

  useEffect(() => {
    handleChangeImage();
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  // Function to handle scroll event
  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  // Attach the scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Adjust the header size based on the scroll position
  useEffect(() => {
    setIsScrolled(scrollPosition > 0);
  }, [scrollPosition]);

  return (
    <Carousel
      className={isScrolled ? styles.carouselScroll : styles.carouselpos}
      interval={null}
    >
      <Carousel.Item>
        <img src={firstImage} width="100%" alt="Radiant Hostel & PG" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={lobby} width="100%" alt="Gallery area, Iron table" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={entrance} width="100%" alt="Entrance of the hostel" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={lib} width="100%" alt="AC Library" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={books} width="100%" alt="Library study table area" />
      </Carousel.Item>
    </Carousel>
  );
}
