import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import logo from "../imgs/combineLogo.png";
import sideBar from "../imgs/icons/sideBar.svg";
import offcanvasLogo from "../imgs/transparentFavicon.png";


import "../styles/DemoNav.css";

export default function DemoNav() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation(); // Get the current location

  useEffect(() => {
    // Scroll to top when a new route is detected
    window.scrollTo(0, 0);
    setIsScrolled(false); // Set isScrolled to false for new page
  }, [location]);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  // Attach the scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Adjust the header size based on the scroll position
  useEffect(() => {
    setIsScrolled(scrollPosition > 0);
  }, [scrollPosition]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const brand = (
    <Navbar.Brand href="/" className="navLogoCenter justify-content-left">
      <img src={logo} alt="Hotel Logo" className="logoSize" />
    </Navbar.Brand>
  );

  const navbarLinks = (
    <ListGroup variant="flush">
      <li className="groupItem">
        <NavLink exact to="/" className="sideLinks">
          Home
        </NavLink>
      </li>
      <li className="groupItem">
        <NavLink to="/amenities" className="sideLinks">
          Amenities
        </NavLink>
      </li>
      <li className="groupItem">
        <NavLink to="/contact" className="sideLinks">
          Contact Us
        </NavLink>
      </li>

      <li className="groupItem">
        <NavLink to="/about" className="sideLinks">
          About Us
        </NavLink>
      </li>
    </ListGroup>
  );

  return (
    <Navbar
      className={isScrolled ? "shrinkBar fixed-top" : "navColor fixed-top"}
      expand="sm"
    >
      <Container>
        {isScrolled ? brand : ""}
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={handleShow}
          style={!isScrolled ? { paddingTop: "3px", paddingBottom: "5px" } : {marginTop:"-10px"}}
        >
          <img src={sideBar} alt="side bar" style={isScrolled ? {width:"28px"}:{width:"20px"}}/>
        </Navbar.Toggle>
        <Offcanvas show={show} onHide={handleClose}>
          <div style={{ backgroundColor: "#242222", paddingBottom: "2rem" }}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title />
            </Offcanvas.Header>
            <div className="offcanvasLogo">
              <img src={offcanvasLogo} alt="hostel logo" width="30%" />
            </div>
          </div>
          <Offcanvas.Body>{navbarLinks}</Offcanvas.Body>
        </Offcanvas>

        {show ? (
          ""
        ) : (
          <Navbar.Collapse id="basic-navbar-nav" style={{flexBasis:"60%"}}>
            <Nav
              className={isScrolled ? "ms-auto" : "mx-auto"}
              defaultActiveKey="/"
            >
              <Nav.Link id="RouterNavLink">
                <NavLink exact="true" to="/" className="links" >
                  Home
                </NavLink>
              </Nav.Link>
              <Nav.Link id="RouterNavLink">
                <NavLink to="/amenities" className="links" >
                  Amenities
                </NavLink>
              </Nav.Link>
              <Nav.Link id="RouterNavLink">
                <NavLink to="/contact" className="links" >
                  Contact Us
                </NavLink>
              </Nav.Link>
              <Nav.Link id="RouterNavLink">
                <NavLink to="/about" className="links" >
                  About Us
                </NavLink>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        )}
      </Container>
    </Navbar>
  );
}
