import WelcomeText from "../components/WelcomeText";
import Layout from "../components/Layout";
import AmenitiesGrid from "../components/AmenitiesGrid";
import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import ReactGA from "react-ga4";

export default function Ameneties() {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Amenities Page"});
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>
          Luxurious Amenities at Radiant Hostel | Vijay Nagar, Indore
        </title>
        <meta
          name="description"
          content="Discover the exceptional amenities at Radiant Hostel in Vijay Nagar, Indore. Our state-of-the-art facilities ensure comfort and convenience for students and working women. Explore a world of modern living at Radiant."
        />
        <link rel="canonical" href="/amenities" />
      </Helmet>
      <WelcomeText heading={"Facilites at Radiant"} para1={""} para2={""} />
      <AmenitiesGrid />
    </Layout>
  );
}
