import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faComments,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";
import faWhatsapp from "../imgs/icons/whatsapp.svg";
import "../styles/ContactUsCard.css";

export default function CardsContact() {
  return (
    <CardGroup>
      <Card>
        <Card.Body>
          <FontAwesomeIcon
            icon={faPhone}
            size="3x"
            style={{ color: "#242222" }}
          />
          <div className="iconContentSpace">
            <h4>Call us Directly</h4>
            <Card.Text className="cardText">
              <Button
                href="tel:6261843543"
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                style={{ fontSize: "18px" }}
              >
              +91 6261843543
              </Button>
              <br />
              <Button
                href="tel:7880109821"
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                style={{ fontSize: "18px", marginTop: "1rem" }}
              >
              +91 7880109821
              </Button>
            </Card.Text>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body style={{ fontSize: "15px", paddingBottom: "0.5rem" }}>
          <FontAwesomeIcon
            icon={faComments}
            size="3x"
            style={{ color: "#242222" }}
          />
          <div className="iconContentSpace">
            <h4>Chat with us</h4>
            <Card.Text className="cardText">
              <Button
                href="https://wa.me/+916261843543/?text=Hello"
                target="_blank"
                rel="noopener noreferrer"
                variant="success"
                size="sm"
                style={{ fontSize: "18px" }}
              >
                <img src={faWhatsapp} alt="whatsapp icon" /> Whatsapp
              </Button>
            </Card.Text>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body style={{ padding: "1rem 0.5rem 1rem" }}>
          <FontAwesomeIcon
            icon={faHandshake}
            size="3x"
            style={{ color: "#242222" }}
          />
          <div className="iconContentSpace">
            <h4>Visit us</h4>
            <Card.Text className="cardText">
              126,127, Sch no.113, opp. Brilliant convention center, Vijay
              Nagar, Indore, Madhya Pradesh 452010
            </Card.Text>
          </div>
        </Card.Body>
      </Card>
    </CardGroup>
  );
}
