import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "../styles/WelcomeText.module.css";

export default function WelcomeText(props) {
  return (
    <Container fluid="md">
      <Row>
        <Col id={styles.lessSpace}>
          <div className={styles.content}>
            <h1>{props.heading}</h1>
            <div className={styles.innerContent}>
              <p>{props.para1}</p>
              <p>{props.para3}</p>
              <p>{props.para2}
              <h6 style={{fontFamily:'Cinzel'}}>{props.impPhrase}</h6></p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
