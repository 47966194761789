import React from "react";
import { MDBFooter, MDBContainer, MDBIcon, MDBBtn } from "mdb-react-ui-kit";
import "../styles/Footer.css";
import neko from "../imgs/neko1.png";

export default function Footer() {
  return (
    <MDBFooter
      className="text-center text-white"
      style={{ backgroundColor: "#916c08", margin: "-16px" }}
    >
      <MDBContainer className="p-4 pb-0">
        <section className="mb-4">
          {/* <MDBBtn
            outline
            color="light"
            floating
            className="m-1"
            href="https://m.facebook.com/p/Radiant-Girls-Hostel-100063627983323/"
            role="button"
          >
            <MDBIcon fab icon="facebook-f" />
          </MDBBtn> */}
          <MDBBtn
            outline
            color="light"
            floating
            className="m-1"
            href="tel:6261843543"
            role="button"
          >
            <MDBIcon fas icon="phone" />
          </MDBBtn>

          <MDBBtn
            outline
            color="light"
            floating
            className="m-1"
            href="https://wa.me/+916261843543/?text=Hello"
            role="button"
          >
            <MDBIcon fab icon="whatsapp" />
          </MDBBtn>

          <MDBBtn
            outline
            color="light"
            floating
            className="m-1"
            href="https://goo.gl/maps/2fTgM8gL9JKk4kFs5"
            role="button"
          >
            <MDBIcon fab icon="google" />
          </MDBBtn>
          <MDBBtn
            outline
            color="light"
            floating
            className="m-1"
            href="https://www.instagram.com/radiant_indore/"
            role="button"
          >
            <MDBIcon fab icon="instagram" />
          </MDBBtn>

          <MDBBtn
            outline
            color="light"
            floating
            className="m-1"
            href="mailto:vinayverma610@gmail.com"
            role="button"
          >
            <MDBIcon fas icon="envelope" />
          </MDBBtn>
        </section>
      </MDBContainer>

      <div className="text-center p-3" style={{ backgroundColor: "#6b4f04" }}>
        © 2023 Copyright: All Rights Reserved {" "}
        <p> 
        {" "} Designed and developed by <img src={neko} className="companyIcon" alt="logo for neko web studios."/> <a href="https://nekowebstudios.com/" className="companyLink" target="_blank" rel="noopener noreferrer">Neko Web Studios</a></p>
      </div>
    </MDBFooter>
  );
}
