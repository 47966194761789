import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import styles from "../styles/Owners.module.css";
import pic from "../imgs/owners1.jpg";

export default function Owners() {
  return (
    <div className={styles.aboutOwners}>
      <Container>
        <Row className={styles.rows}>
          <Col sm={5}>
            <div className={styles.imageContainer}>
              <Image src={pic} fluid alt="Owners of Radiant Girls Hostel & PG."/>
              <div className={styles.lowerDiv}></div>
            </div>
          </Col>
          <Col sm={7} className={styles.rightGrid}>
            <div className={styles.ownersContent}>
              <h1>Meet The Hearts Behind Radiant</h1>
              <p>
                Mr. Nandkishore Verma and Mrs. Madhu Verma, the beautiful
                couple behind Radiant, bring a wealth of experience and care to
                the hostel. Their dedication to fostering a supportive
                environment reflects in every corner of Radiant. With their
                attentive guidance, Radiant remains a space where your needs are
                understood and catered to, ensuring an experience that goes
                beyond just accommodation.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
