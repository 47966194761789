import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import group2 from "../imgs/journeyCarousel/group2.jpg";
import cakeCutting from "../imgs/journeyCarousel/cakeCutting.jpg";
import cakeCutting2 from "../imgs/journeyCarousel/cakeCutting2.jpg";
import pooja from "../imgs/journeyCarousel/pooja.jpg";
import indDay from "../imgs/journeyCarousel/independanceDay.jpg"
import flag from "../imgs/journeyCarousel/flagHosting.jpg";
import atb from "../imgs/journeyCarousel/atb.jpg";
import tilak from "../imgs/journeyCarousel/tilak.jpg";
import "../styles/OurJourney.css";

export default function OurJourney() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="carouselJourney">
      <h1 style={{ paddingBottom: "4rem", color: "#242222" }}>Our Journey</h1>
      <Slider {...settings}>
      <div className="roomgalleryBox">
          <img loading="lazy" src={indDay} width="100%" alt="independance day celebration" className="images" />
          <div className="bottom-left">Independance Day 2023</div>
        </div>
        <div className="roomgalleryBox">
          <img loading="lazy" src={flag} width="100%" alt="Flag Hosting" className="images" />
          <div className="bottom-left">Flag Hosting on 15 Aug 2023</div>
        </div>
        <div className="roomgalleryBox">
          <img loading="lazy" src={group2} width="100%" alt="All residents and owners" className="images" />
          <div className="bottom-left">Photoshoot at radiant</div>
        </div>
        <div className="roomgalleryBox">
          <img loading="lazy"
            src={cakeCutting}
            width="100%"
            alt="Anniversary Celebration" 
            className="images"
          />
          <div className="bottom-left">Hostel's Anniversary Celebration</div>
        </div>
        <div className="roomgalleryBox">
          <img loading="lazy" src={pooja} width="100%" alt="Saraswati Pooja" className="images" />
          <div className="bottom-left">Saraswati Pooja</div>
        </div>
        <div className="roomgalleryBox">
          <img loading="lazy" src={atb} width="100%" alt="Wishing luck for exams" className="images" />
          <div className="bottom-left">Wishing luck for Neet</div>
        </div>
        <div className="roomgalleryBox">
          <img loading="lazy"
            src={cakeCutting2}
            width="100%"
            alt="Cake cutting"
            className="images"
          />
          <div className="bottom-left">Anniversary Celeration</div> 
        </div>
        <div className="roomgalleryBox">
          <img loading="lazy" src={tilak} width="100%" alt="Pooja before an exam" className="images" />
          <div className="bottom-left">Aarti and tilak before exam</div>
        </div>
      </Slider>
    </div>
  );
}
