import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import Layout from "../components/Layout";
import Owners from "../components/Owners";
import WelcomeText from "../components/WelcomeText";

export default function About() {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "About Page" });
  }, []);

  const paraOne =
    "Nestled in the heart of the city, Radiant Hostel & PG offers a unique living experience tailored exclusively for female students and working women. Ever since we opened our doors in 2020, we had one goal in mind: offering our residents a comfortable, affordable and all around exceptional accommodation. From spacious rooms adorned with modern amenities to well-equipped common areas, we've meticulously crafted every aspect to cater to your needs.";
  const paraTwo =
    "At Radiant, safety is our utmost priority. With round-the-clock security, CCTV surveillance, and access control measures, you can be assured of a safe living space that feels just like home. Radiant isn't just a place to stay; it's a close-knit family. Celebrations and festivities are an integral part of life at Radiant.  Join our Radiant family and embark on a journey where your dreams are nurtured, your aspirations supported, and your happiness celebrated.";
  return (
    <Layout>
    <Helmet>
        <title>
        About Radiant Hostel & PG | Safe Accommodation for Women in Vijay Nagar, Indore
        </title>
        <meta
          name="description"
          content="Learn about the inspiring journey and vision behind Radiant Hostel in Vijay Nagar, Indore. Founded in 2019, Radiant offers a secure and welcoming environment for students and working women. Discover our commitment to comfort and community."
        />
        <link rel="canonical" href="/about" />
      </Helmet>
      <WelcomeText
        heading={"All About Radiant"}
        para1={paraOne}
        para2={paraTwo}
      />
      <Owners />
    </Layout>
  );
}
