import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import styles from "../styles/ImageGrid.module.css";
import room1 from "../imgs/room1.webp";
import room2 from "../imgs/room2.webp";
import room3 from "../imgs/room3.webp";
import room4 from "../imgs/room4.webp";
import almira from "../imgs/almira.webp";
import mess from "../imgs/mess.webp";
import fridge from "../imgs/fridge.webp"; 
import lib from "../imgs/lib.webp";
import lib1 from "../imgs/lib1.webp";
import lib2 from "../imgs/libTable.webp";
import GoogleMapComp from "./GoogleMapComp";
import OurJourney from "./OurJourney";

export default function ImageGrid() {
  return (
    <div className={styles.watermark}>
      <Container>
        <Row className={styles.rows} id={styles.top}>
          <Col sm={4} className={styles.leftGrid}>
            <h1>Rooms</h1>
            <hr />
            <ul className={styles.imageGridList}>
              <li className={styles.listItem}>AC / Non-AC twin rooms</li>
              <li className={styles.listItem}>Single rooms available</li>
              <li className={styles.listItem}>Two Cupboard in each room</li>
              <li className={styles.listItem}>Study table with chair in each room</li>
              <li className={styles.listItem}>One Folding table in each room</li>
              <li className={styles.listItem}>Attached Bathroom</li>
              <li className={styles.listItem}>Dressing Table with mirror</li>
              <li className={styles.listItem}>Daily room cleaning</li>
            </ul>
          </Col>
          <Col sm={8}>
            <div className={styles.imageContainer}>
              <Carousel>
                <Carousel.Item interval={2000}>
                  <img src={room1} width="100%" alt="Non-AC Single Room" loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img src={room4} width="100%" alt="Non-AC twin bedroom" loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img src={room2} width="100%" alt="AC twin bedroom" loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img src={room3} width="100%" alt="Study table, folding table, dressing table and mirror" loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img src={almira} width="100%" alt="Two Almira and study table" loading="lazy"/>
                </Carousel.Item>
              </Carousel>
              <div className={styles.lowerDiv}></div>
            </div>
          </Col>
        </Row>
        <Row className={styles.rows}>
          <Col sm={4} className={styles.leftGrid}>
            <h1>Dining Hall</h1>
            <hr />
            <ul className={styles.imageGridList}>
              <li className={styles.listItem}>Roof top dining area</li>
              <li className={styles.listItem}>3 nutrious meals a day</li>
              <li className={styles.listItem}>Healthy & tasty food made by maharaj ji</li>
              <li className={styles.listItem}>Evening Tea with snacks</li>
              <li className={styles.listItem}>Hot milk provided</li>
              </ul>
          </Col>
          <Col sm={8}>
            <div className={styles.imageContainer}>
            <Carousel>
                <Carousel.Item interval={2000}>
                  <img src={mess} width="100%" alt="Rooftop Dining area" loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img src={fridge} width="100%" alt="Common Fridge, Rooftop dinig area and Kitchen." loading="lazy"/>
                </Carousel.Item>
              </Carousel>
              <div className={styles.lowerDiv}></div>
            </div>
          </Col>
        </Row>
        <Row className={styles.rows}>
          <Col sm={4} className={styles.leftGrid}>
            <h1>Library / Work space</h1>
            <hr />
            <ul className={styles.imageGridList}>
              <li className={styles.listItem}>Air Conditioned Library</li>
              <li className={styles.listItem}>Softboards on table</li>
              <li className={styles.listItem}>Allocated study space for everyone</li>
              <li className={styles.listItem}>Books can be issued</li>
              <li className={styles.listItem}>attached washroom</li>
            </ul>
          </Col>
          <Col sm={8}>
            <div className={styles.imageContainer}>
              {/* <Image src={libEmpty} width="100%" fluid /> */}
              <Carousel>
                <Carousel.Item interval={2000}>
                  <img src={lib} width="100%" alt="Air Conditioned Library" loading="lazy" />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img src={lib1} width="100%" alt="Books Provided for issuing" loading="lazy" />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img src={lib2} width="100%" alt="Softboard and assigned study area in library." loading="lazy"/>
                </Carousel.Item>
              </Carousel>
              <div className={styles.lowerDiv}></div>
            </div>
          </Col>
        </Row>
      </Container>
      <OurJourney/> 
      <GoogleMapComp />
    </div>
  );
}
