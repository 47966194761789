import * as React from "react";
import { useEffect } from "react";
import { createBrowserRouter, RouterProvider} from "react-router-dom";
import ReactGA from "react-ga4";
import "bootstrap/dist/css/bootstrap.min.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./App.css";
import HomePage from './pages/HomePage'
import About from './pages/about'
import Contact from './pages/contact'
import Root from "./pages/Root";
import ErrorPage from "./pages/ErrorPage";
import Amenities from "./pages/amenities";

const router = createBrowserRouter([
  {path:'/',
  element: <Root/>,
  errorElement: <ErrorPage/>,
  children:[
    {path:'/', element: <HomePage/>},
    {path:'/about', element: <About/>},
    {path:'/contact', element: <Contact/>},
    {path:'/amenities', element: <Amenities/>}
  ]}
]);

const TRACKING_ID = "G-XT3TRBB2SN";  
ReactGA.initialize(TRACKING_ID);

export default function Home() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Home Page" });
  }, []);

  return (
    <React.Fragment>
      <RouterProvider router={router} />
    </React.Fragment>
  );
}
