import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "../styles/grid.module.css";
import wifi from "../imgs/icons/Wifi.svg";
import bio from "../imgs/icons/bio.svg";
import lib from "../imgs/icons/book.svg";
import cctv from "../imgs/icons/cctv.svg";

export default function Grid() {
  return (
    <Container className={styles.centeredContainer}>
      <Row className="justify-content-center">
        <Col xs={6} md={3} className={styles.roundedIcon}>
        <div className={styles.icon}>
            <img src={wifi} alt="Wifi icon" id={styles.imgIcon}/>
          </div>
          <h5 className={styles.iconDescription}>High Speed Wifi</h5>
        </Col>
        <Col xs={6} md={3} className={styles.roundedIcon}>
          <div className={styles.icon}>
            <img src={bio} alt="Biometric Secuirty icon" id={styles.imgIcon}/>
          </div>
          <h5 className={styles.iconDescription}>Biometric Security</h5>
        </Col>
        <Col xs={6} md={3} className={styles.roundedIcon}>
          <div className={styles.icon}>
            <img src={lib} alt="Books icon" id={styles.imgIcon}/>
          </div>
          <h5 className={styles.iconDescription}>AC Library</h5>
        </Col>
        <Col xs={6} md={3} className={styles.roundedIcon}>
          <div className={styles.icon}>
            <img src={cctv} alt="CCTV icon" id={styles.imgIcon}/>
          </div>
          <h5 className={styles.iconDescription}>CCTV Monitoring</h5>
        </Col>
      </Row>
    </Container>
  );
}
