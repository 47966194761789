import React from 'react'
import WelcomeText from './WelcomeText'

export default function NearUs() {

    const heading = "Near us";
    const paraOne = "Our location ensures that the residents are just minutes away from basically anything one might need. This includes a range of Hospitals & Pharmacies, ATMs & Banks, several convenience stores & supermarkets, a vibrant array of restaurants and cafes, coaching centres like Allen, Colleges, Schools, Shopping Centres, Salons & Parlours, Petrol Pumps, etc. Easy access to public transportation enables safe and cheaper way to connect to the rest of the city, including railway station and airport. Indore is proudly constructing its very own Metro route, and has planned a metro station at Bapat Square - just over a kilometer from our doorstep.";

  return (
    <div style={{padding:"1.5rem 0rem"}}>
    <WelcomeText
        heading={heading}
        para1={paraOne}
    />
    </div>
  )
}
