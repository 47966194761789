import React from 'react'
import Footer from './Footer'
import CarouselHome from './CarouselHome';
import DemoNav from './DemoNav';
import HeaderLogo from './HeaderLogo';



export default function Layout({children}) {
  return (
    <React.Fragment>
    <div style={{display:"flex", flexDirection: "row"}}>
    <HeaderLogo/>
    <DemoNav/>
    <CarouselHome/>
    </div>
    <div>
        {children}
    </div>
    <Footer/>
    </React.Fragment>
  )
}
