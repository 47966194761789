import "../styles/HeaderLogo.css";
import { useState, useEffect } from "react";
import logo from "../imgs/logo3.png";

export default function HeaderLogo() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  // Function to handle scroll event
  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  // Attach the scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Adjust the header size based on the scroll position
  useEffect(() => {
    setIsScrolled(scrollPosition > 0);
  }, [scrollPosition]);

  if (isScrolled > 10) {
    return <div></div>;
  } else {
    return (
      <header className="expanded fixed-top">
        <img
          src={logo}
          alt="Hostel Logo"
          id="toplogo"
          className={isScrolled ? "over1" : ""}
        />
      </header>
    );
  }
}
