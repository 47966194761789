import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
// import "@fortawesome/fontawesome-free/css/all.min.css";
import Layout from "../components/Layout";
import Grid from "../components/Grid";
import WelcomeText from "../components/WelcomeText";
import ImageGrid from "../components/ImageGrid";
import { Helmet } from "react-helmet-async";
import NearUs from "../components/NearUs";

export default function HomePage() {
  const paraOne =
    "Radiant welcomes both female students and working women, recognizing the diverse needs of our residents. Whether you're a dedicated student pursuing your dreams or a professional woman excelling in your career, our hostel & PG provides the ideal environment to thrive. We offer numerous amenities and services to make your stay comfortable and secure. We’re dedicated to offer a blend of comfort and convenience to provide you with the attention and luxury you deserve. Featuring impeccable accommodations and an attentive staff, we guarantee you’ll have a pleasant experience here.";
  const paraTwo =
    "Moving out of your home for academics or work is a huge step, but we’re here to help. We have designed our accommodations keeping your wellbeing in mind, so that you can have a safe space, ";
  const impPhrase = "YOUR SECOND HOME.";
  return (
    <Layout>
      <Helmet>
        <title>Radiant Hostel & PG | A Home for Students & Working Women</title>
        <meta
          name="description"
          content="Welcome to Radiant Hostel, a secure and comfortable haven in Vijay Nagar, Indore. Discover our exceptional accommodations for students and working women. Join our vibrant community and experience a home away from home."
        />
        <link rel="canonical" href="/" />
        <meta
          property="og:title"
          content="Radiant Hostel & PG | A Safe Haven for Students & Working Women"
        />
        <meta
          property="og:description"
          content="Welcome to Radiant Hostel, a secure and comfortable haven in Vijay Nagar, Indore. Discover our exceptional accommodations for students and working women. Join our vibrant community and experience a home away from home."
        />
        {/* <meta property="og:image" content=""/> */}
        <meta property="og:url" content="/"></meta>
      </Helmet>
      <WelcomeText
        heading={"Welcome to Radiant"}
        para1={paraOne}
        para2={paraTwo}
        impPhrase={impPhrase}
      />
      <Grid />
      <ImageGrid />
      <NearUs/>
    </Layout>
  );
}
