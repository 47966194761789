import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import Layout from "../components/Layout";
import WelcomeText from "../components/WelcomeText";
import GoogleMapComp from "../components/GoogleMapComp";
import CardsContact from "../components/CardsContact";
export default function Contact() {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Contact Page"});
  }, []);

  const paraOne =
    "We understand the importance of seamless communication when it comes to your concerns and queries. Whether you're seeking information about Radiant Hostel & PG or planning a visit to our establishment, we're here to provide you with all the assistance you need.";
  const paraTwo =
    "You can reach out to us through call / WhatsApp or directly visit us at Radiant girls hostel & PG to see for yourself the facilities that we provide.";
  return (
    <Layout>
    <Helmet>
        <title>
        Contact Radiant Hostel & PG| Get in Touch for Safe Accommodation
        </title>
        <meta
          name="description"
          content="Contact Radiant Hostel in Vijay Nagar, Indore for inquiries about safe and secure accommodations for women. Reach out to us via phone or WhatsApp to learn more about our facilities, services, and the welcoming Radiant community."
        />
        <link rel="canonical" href="/contact" />
      </Helmet>
      <WelcomeText
        heading={"Get in Touch with us."}
        para1={paraOne}
        para2={paraTwo}
      />
      <CardsContact/>
      <div style={{backgroundColor:"white", margin:"-16px"}}>
      <GoogleMapComp/>
      </div>
    </Layout>
  );
}
