import React from "react";
import "../styles/GoogleMapsComp.css";

export default function GoogleMapComp() {
  return (
    <div className="map-responsive">
      <h1 style={{ padding: "20px 0px" }}>Find us here</h1>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.049780754479!2d75.87802697530616!3d22.763533779356134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396303e1e5c1eb77%3A0x9b620aec3542a374!2sRadiant%20Girls%20Hostel%20%26%20Library!5e0!3m2!1sen!2sin!4v1690991543423!5m2!1sen!2sin"
          width="100%"
          height="450"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Responsive Google Maps"
        ></iframe>
      </div>
    </div>
  );
}

