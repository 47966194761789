import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck } from "@fortawesome/free-regular-svg-icons";
import "../styles/AmenitiesGrid.css"; 

export default function TryTable() {
  return (
    <div className='daba'>
      <ul class="press">
          <li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              /> 
            </div>
            <span>24 Hour Security</span>
          </li><li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />{" "}
               
            </div>
            <span>Ganesh Mandir</span>
          </li><li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />{" "}
              
            </div>
            <span>Medical Facility on demand</span>
          </li>
          <li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />
            </div>
            <span>24 Hour Lady Warden</span>
          </li><li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />{" "}
               
            </div>
            <span>RO Water Cooler</span>
          </li><li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />{" "}
            </div>
            <span>3 Nutritious Meals a Day</span>
          </li>
          <li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />
            </div>
            <span>CCTV Security</span>
          </li><li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />{" "}
               
            </div>
            <span>Common Fridge</span>
          </li><li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />{" "}
              
            </div>
            <span>Evening Tea with Snacks</span>
          </li>
          <li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />
            </div>
            <span>Washing Machine (automatic)</span>
          </li><li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />{" "}
               
            </div>
            <span>Common Induction & Cooking Space</span>
          </li><li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />{" "}
              
            </div>
            <span>Special Meals on Festival</span>
          </li>
          <li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />
            </div>
            <span>Iron for Clothes</span>
          </li><li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />{" "}
              
            </div>
            <span>High Speed Wifi</span>
          </li><li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />{" "}
              
            </div>
            <span>Proper Ventillation in Rooms & Hall</span>
          </li>
          <li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />
            </div>
            <span>AC / Non-AC rooms</span>
          </li><li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />{" "}
               
            </div>
            <span>Folding Table in Each Room</span>
          </li><li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />{" "}
              
            </div>
            <span>Solar Water Heater</span>
          </li>
          <li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />
            </div>
            <span>Neat & Clean Rooms</span>
          </li><li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />{" "}
               
            </div>
            <span>Air Conditioned Library</span>
          </li><li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />{" "}
              
            </div>
            <span>Lift for All Floors</span>
          </li>
          <li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />{" "} 
            </div>
            <span>Spacious Rooms</span>
          </li><li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />{" "}
                
            </div>
            <span>Fire Extinguisher</span>
          </li><li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />{" "}
              
            </div>
            <span>Parking Space in front of PG</span>
          </li>
          <li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />{" "} 
            </div>
            <span>Regular Cleaning of Rooms</span>
          </li><li>
            <div class="press-logo">
            <FontAwesomeIcon
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />{" "}
                
            </div>
            <span>Water Softner</span>
          </li><li>
            <div class="press-logo">
            <FontAwesomeIcon 
                icon={faSquareCheck}
                size="xl"
                style={{ color: "#d29f14" }}
              />
            </div>
            <span>Homely Environment</span>
          </li>
        </ul>
    </div>
  )
}
