import {useEffect} from 'react'
import Layout from '../components/Layout'

export default function ErrorPage() {
  useEffect(()=>{

    const savedScrollPosition = localStorage.getItem('scrollPosition');
    if (savedScrollPosition !== null) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
    }

  },[])
  return (
    <Layout>
        <h1>An error occured!</h1>
    </Layout>
  )
}
